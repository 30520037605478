.contact__item {
    padding: 5px 0 5px 2em;
    font: .9rem light, serif;
    color: var(--color__text);

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    address {
        margin-bottom: 0;
    }
}
