.footer-widgets {
    .widget_media_image {
        padding-top: 10% !important;
        @media (--ipad){
            padding-top: 0 !important;
        }
        @media (--ipad_mini){
            padding-top: 0 !important;
        }
        @media (--phone){
            padding-top: 0 !important;
        }
    }

    .widget {
        margin-bottom: 0;
        @media (--ipad){
            margin-bottom: 2em;
        }
        @media (--ipad_mini){
            margin-bottom: 2em;
        }
        @media (--phone){
            margin-bottom: 2em;
        }
    }
}
