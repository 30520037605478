.content {
    padding-left: 30px;
    padding-right: 30px;
    font: 1rem light, serif;

    p + p, 
    ul, ol,
    li + li{
        margin-top: 1em;
    }

    ul {
        list-style-position: inside;
    }
}
