.site-header {
    border-top: 6px solid var(--color__brown);
    background-color: color(white);

    &.float-header {
        background-color: color(white);
    }

    .btn-menu {
        margin-top: 0;
        margin-right: 15px;
        line-height: 1;
    }
}
