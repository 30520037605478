.btn_color_white {
    border-color: white;
    background-color: transparent;
    color: white;

    &:hover {
        background-color: white;
        color: var(--color__brown);
    }
}
