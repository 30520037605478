.banner {
    position: relative;

    @media (--phone){
        img {
            height: 200px;
            object-fit: cover;
        }
    }
}
