.shop {
    @mixin fullWidth;

    .button.product_type_simple {
        margin-top: 0 !important;
        margin-bottom: 2em;
        padding: .4em 2em;
        border: 1px solid var(--color__brown);
        border-radius: .4em;
        background-color: transparent;
        text-transform: uppercase;
        font: 12px regular, serif;
        color: var(--color__brown);
    }
}
