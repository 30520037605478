.woocommerce {
    > .products {
        margin-bottom: 0 !important;
    }

    .product {
        margin-bottom: 1.5em !important;
        transition: box-shadow .4s linear;

        &:hover {
            box-shadow: 8px 6px 10px color(black a(.3));
        }
    }

    .product__code {
        padding-bottom: 1em;
        font: 1rem light, serif;
        color: var(--color__brown);
    }

    .attachment-woocommerce_thumbnail {
        margin-bottom: 0 !important;
    }

    .woocommerce-loop-product__title {
        padding-bottom: 0 !important;
        font: 1rem bold, serif;
        color: var(--color__brown);
    }
}
