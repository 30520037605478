.feature__info {
    position: absolute;
    bottom: 0;
    left: 10px;
    width: calc(100% - (10px * 2));
    padding: .7em 1.6em;
    background-color: color(var(--color__brown) a(.9));
    text-align: center;
    transition: top .4s linear;
}
