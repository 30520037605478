.feature {
    position: relative;
    padding: 0 10px;
    cursor: pointer;
    @media (--phone){
        + .feature {
            margin-top: 1em;
        }
    }

    &:hover {
        .feature {
            &__info {
                top: 0;
                padding-top: 1.6em;
                padding-bottom: 1.6em;
            }

            &__text {
                max-height: 20em;
                margin-top: 1em;
                transition: max-height .8s linear .1s;
            }
        }
    }
}
