.widget_nav_menu {
    .menu-item {
        line-height: 1;

        > a {
            font: 14px light, serif;
            color: black !important;
        }

        &.current-menu-item,
        &.current_page_item {
            > a {
                font-family: bold, serif;
            }
        }
    }
}
