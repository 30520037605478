@define-mixin formInput {
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: transparent;
    font: .8rem light, serif;
    color: white;

    &:focus {
        outline: 0;
    }

    &::placeholder {
        opacity: 1;
        color: white;
    }
}
.wpcf7 {
    input {
        &[type="text"],
        &[type="tel"],
        &[type="email"] {
            width: 100%;
            @mixin formInput;
        }

        &[type="submit"] {
            margin-top: 1em;
            padding: .3em 3em;
            background-color: white;
            border-radius: .4em;
            text-transform: lowercase;
            font: .9rem bold, serif;
            color: var(--color__green);

            &:hover {
                background-color: transparent;
                color: white;
            }
        }
    }

    textarea {
        width: 100%;
        height: 7em;
        @mixin formInput;
        resize: none;
    }
}
