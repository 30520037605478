:root {
    --color__brown: #594a42;
    --color__red: #bd1e2d;
    --color__green: #305327;
    --color__text: #575756;

    --zIndex__top: 9999;
    --zIndex__middle: 999;
    --zIndex__bottom: 99;
}

@define-mixin fullWidth {
    #primary {
        width: 100%;
    }

    .site-main {
        padding: 3em 0;
    }

    .woocommerce-breadcrumb,
    .woocommerce-products-header,
    .woocommerce-result-count,
    .woocommerce-ordering {
        display: none;
    }
}
