.listing-item__content {
    display: block;
    overflow: hidden;
    position: relative;

    &:hover {
        .listing-item__info {
            transform:  translateY(0);
        }
    }
}
