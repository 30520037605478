.social__icon > a {
    display: block;
    width: 30px;
    height: 30px;
    font-size: 0;
    transition: filter .4s linear;

    &:hover {
        filter: grayscale(100%);
    }
}

@each $i in facebook, twitter, linkedin {
    .social__icon_$(i) > a {
        background: svg-load('../../../../images/social_$(i).svg') center/contain no-repeat;
    }
}
