#mainnav {
  .menu > .menu-item {
    padding: 0 1em;

    &:hover {
      .sub-menu {
        left: 0 !important;
      }
    }

    > a {
      font-size: 16px;

      &:hover {
        font-family: bold, serif;
      }
    }

    &.current-menu-item,
    &.current_page_item {
      > a {
        font-family: bold, serif;
      }
    }
  }

  .sub-menu {
    left: 0;
  }
}
