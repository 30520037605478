.contact__icon {
    position: relative;
    padding-left: 1.4em !important;
    @media (--ipad){
        padding-left: 2em !important;
    }
    @media (--ipad_mini){
        padding-left: 2em !important;
    }
    @media (--phone){
        padding-left: 2em !important;
    }

    &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 20px;
        height: 20px;
        background: url(../images/icons_contact.png) left top/auto 100% no-repeat;
        @media (--ipad){
            top: 0;
        }
        @media (--ipad_mini){
            top: 0;
        }
        @media (--phone){
            top: 0;
        }
    }
}

@each $i in address, tel, email {
    .contact__icon_$(i) {
        &:before {
            @if $i == address {
                background-position-x: 0;
            } @else if $i == tel {
                background-position-x: -20px;
            } @else if $i == email {
                background-position-x: -45px;
            }
        }
    }
}
