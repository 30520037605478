.listing-item__info {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1em;
    background-color: color(var(--color__brown) a(.9));
    transform:  translateY(-100%);
    transition: transform .5s linear;
}
