.banner__info {
    position: absolute;
    top: 50%;
    left: 10%;
    width: 50%;
    transform:  translateY(-50%);
    @media (--phone){
        width: 80%;
    }
}
