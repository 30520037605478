.display-posts-listing {
    display: flex;
    list-style: none;
    flex-flow: row wrap;
    justify-content: space-between;

    .attachment-full {
        width: 100%;
    }
}
