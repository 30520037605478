.btn {
    display: inline-block;
    padding: .3em 2em;
    border: 1px solid var(--color__brown);
    border-radius: .5em;
    text-transform: uppercase;
    font: 12px regular, serif;
    color: var(--color__brown);
    transition: background-color .4s linear,
                color .4s linear;

    &:hover {
        background-color: var(--color__brown);
        color: white;
    }
}
