.shop-product {
    @mixin fullWidth;
    @media (--phone){
        .site-main {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .product {
        margin-bottom: 0 !important;
        
        &:hover {
            box-shadow: none;
        }
    }

    .product_title {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
        font: 1.4rem bold, serif !important;
    }

    .woocommerce-product-details__short-description {
        margin-bottom: 2em;
        font: 1rem light, serif;

        p {
            margin-bottom: 0;
        }
    }

    .description {
        font: .8rem light, serif;
        color: var(--color__brown);

        h1, h2, h3, 
        h4, h5, h6 {
            margin-top: 1em;
        }

        p + p {
            margin-top: 1em;
        }

        ul,
        ol {
            margin-top: 1em;
            list-style-position: inside;
        }
    }

    .price,
    .product_meta,
    .woocommerce-tabs.wc-tabs-wrapper,
    .related.products{
        display: none;
    }
}
