.owl-carousel {
    &.sa_owl_theme .owl-nav button {
        width: 20px !important;
        height: 30px !important;
    }
}

.white .sa_owl_theme .owl-nav button.owl-prev,
.white .sa_owl_theme .owl-nav button.owl-next {
    background-size: auto 70% !important;
}
